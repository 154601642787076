import React, { useState } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './AboutUs.css';

const AboutUs = () => {
    const [activeSpot, setActiveSpot] = useState(null);

    const facilitySpots = [
        {
            id: 'padok2',
            name: 'Padok 2',
            description: 'Atlarımızın güvenli ve konforlu egzersiz alanı',
            top: '9%',
            left: '58%'
        },
        {
            id: 'misafirevi',
            name: 'Misafir Evi',
            description: 'Konuklarımız için özel tasarlanmış konforlu konaklama alanı',
            top: '23%',
            left: '58%'
        },
        {
            id: 'padok1',
            name: 'Padok 1',
            description: 'Ana padok alanımız ve round pen',
            top: '48%',
            left: '72%'
        },
        {
            id: 'round',
            name: 'Round Pen',
            description: 'Round pen alanımız',
            top: '53%',
            left: '62%'
        },
        {
            id: 'manej',
            name: 'Manej',
            description: 'Profesyonel binicilik eğitimlerinin verildiği kapalı manej alanı',
            top: '38%',
            left: '35%'
        },
        {
            id: 'kafe',
            name: 'Kafe',
            description: 'Misafirlerimiz için özel menüler sunan kafemiz',
            top: '65%',
            left: '42%'
        },
        {
            id: 'otopark',
            name: 'Otopark',
            description: 'Araçlarınız için ücretsiz otopark alanımız',
            top: '59%',
            left: '32%'
        },
        {
            id: 'atboxlari',
            name: 'At Boxları',
            description: 'Atlarımızın konforlu yaşam alanları',
            top: '78%',
            left: '46%'
        },
        {
            id: 'cardak',
            name: 'Çardak',
            description: 'Maneji izlerken keyifle vakit geçireceğiniz alan.',
            top: '32%',
            left: '46%'
        },
        {
            id: 'atbox2',
            name: 'At Boxları',
            description: 'Atlarımızın konforlu yaşam alanları',
            top: '68%',
            left: '53%'
        }
        ,
        {
            id: 'oyun2',
            name: 'Oyun Parkı',
            description: 'Çocuklarınız buraya bayılacak',
            top: '63%',
            left: '53%'
        },
        {
            id: 'etkinlik',
            name: 'Etkinlik Alanı',
            description: 'Özel anlar için özel alanımız',
            top: '68%',
            left: '64%'
        }
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            }
        ]
    };

    const testimonials = [
        {
            name: "Ali Yılmaz",
            feedback: "Atiye Atlı Yaşam, huzur ve doğayı bir araya getiriyor. Çocuklarımız binicilik derslerinden çok keyif aldı!",
            image: "/img/at1.webp"
        },
        {
            name: "Merve Demir",
            feedback: "Doğanın ortasında, harika bir deneyim! At pansiyonu hizmetleri gerçekten profesyonel.",
            image: "/img/at2.webp"
        },
        {
            name: "Ahmet Kaya",
            feedback: "Atlar ve doğa ile iç içe olmak beni çok mutlu etti. Herkes için tavsiye ederim.",
            image: "/img/at3.webp"
        },
        {
            name: "Ayşe Şahin",
            feedback: "Çocuklarımın doğum gününü burada kutladık ve mükemmel bir deneyim yaşadık. Hizmet kalitesi harika!",
            image: "/img/at4.webp"
        },
        {
            name: "Mehmet Çelik",
            feedback: "Atiye Atlı Yaşam, doğa ile bağ kurmak için harika bir yer. Atlı okçuluk denemek müthişti.",
            image: "/img/at5.webp"
        }
    ];

    return (
        <div className="h-full container mx-auto p-4">
            <div className="sm:w-[730px] w-full">
                <h1 className="font-bold text-5xl mt-40 text-blue-900">Hakkımızda</h1>
                <p className="font-normal text-lg mt-10 px-4 md:px-0">
                Atiye Atlı Yaşam, doğanın kalbinde, atçılık ve geleneksel sporları bir araya getiren bir yaşam alanıdır. Kurulduğu günden bu yana misafirlerimize huzur dolu bir ortam sunmayı amaçlayan çiftliğimiz, doğayla iç içe unutulmaz deneyimler yaşatmaktadır. Binicilik derslerinden okçuluğa, at pansiyonundan etkinlik alanlarına kadar geniş bir hizmet yelpazesiyle, her yaştan doğa ve spor tutkunlarına hitap ediyoruz. Misafirlerimizin memnuniyeti ve doğaya olan saygımız, çalışmalarımızın temelini oluşturuyor.
                </p>
            </div>
            <div className="border-b border-gray-500 mt-20 container mx-auto" style={{ borderColor: "rgba(169, 169, 169, 0.5)" }}></div>

            <div className="grid sm:grid-cols-4 grid-cols-1 justify-center  gap-4 mt-20 px-4 md:px-0">
                <div className="flex flex-col sm:p-4 p-2">
                    <h1 className="font-bold text-lg">Misyonumuz:</h1>
                    <p>
                    Doğanın huzurunu ve atların büyüleyici dünyasını bir araya getirerek, misafirlerimize unutulmaz deneyimler sunmak. Sürdürülebilirlik anlayışıyla çalışarak, doğaya saygılı hizmetlerimizle fark yaratmayı hedefliyoruz.
                    </p>
                </div>
                <div className="flex flex-col sm:p-4 p-2">
                    <h1 className="font-bold text-lg">Vizyonumuz:</h1>
                    <p>
                    Atiye Atlı Yaşam olarak, Türkiye’nin önde gelen at çiftliklerinden biri olmak ve uluslararası alanda tanınan bir marka haline gelmek istiyoruz. Geleneksel sporları modern olanaklarla buluşturup, misafirlerimize eşsiz bir deneyim sunmayı amaçlıyoruz.
                    </p>
                </div>
                <div className="flex flex-col sm:p-4 p-2">
                    <h1 className="font-bold text-lg">Kalite</h1>
                    <p>
                    Hizmetlerimizde kaliteyi bir öncelik olarak görüyoruz. Atlarımızın sağlığı, misafirlerimizin güvenliği ve doğayla uyumlu bir çalışma anlayışı, her zaman önceliğimizdir. Hizmetlerimizin her aşamasında en yüksek standartları benimseyerek sizlere keyifli ve güvenli bir deneyim sunuyoruz.
                    </p>
                </div>
                <div className="flex flex-col sm:p-4 p-2">
                    <h1 className="font-bold text-lg">Tecrübe</h1>
                    <p>
                    Yılların verdiği deneyimle, binicilik, at pansiyonu ve geleneksel sporlar alanında uzmanlaşmış bir ekip olarak hizmet veriyoruz. Misafirlerimizin ihtiyaçlarını anlayarak, onlara özel çözümler sunuyor ve her ziyareti eşsiz bir deneyime dönüştürüyoruz.
                    </p>
                </div>
            </div>

            <div className="w-full" name="kampanya">
                <div className="flex flex-col items-center justify-center">
                    <div className="text-center font-extrabold text-4xl mt-40 sm:w-[730px] w-full px-4 md:px-0">
                        "Doğayı ve atlarımızı seven, işine tutkuyla bağlı bir ekiple çalışıyoruz. Uzman eğitmenlerimiz ve deneyimli personelimiz sayesinde her misafirimize unutulmaz bir deneyim sunmayı amaçlıyoruz. Atiye Atlı Yaşam, yalnızca bir çiftlik değil, bir aile ortamıdır."
                    </div>
                </div>
            </div>

            <div className="z-10 mx-auto container relative mt-40">
                <h1 className="text-blue-900 font-bold text-5xl mb-7 px-4 md:px-0">
                    Misafir Memnuniyetinin Adresi
                </h1>
                <h1 className="font-normal text-xl px-4 md:px-0">
                Atiye Atlı Yaşam olarak, doğayla iç içe huzurlu bir ortamda misafirlerimize unutulmaz bir deneyim sunuyoruz. Binicilik derslerinden okçuluğa, etkinlik alanlarından at pansiyonuna kadar sunduğumuz her hizmet, misafirlerimizin memnuniyetini kazanma hedefiyle şekilleniyor. Doğayı seven misafirlerimizden aldığımız olumlu geri bildirimler, kalite ve güvenilir hizmet anlayışımızın bir göstergesidir. Siz de bu eşsiz deneyimi yaşamak için bizi ziyaret edebilirsiniz.
                </h1>
            </div>

            <Slider {...settings}>
                    {testimonials.map((testimonial, index) => (
                        <div key={index} className="p-4">
                            <div className="bg-white rounded-lg  p-6">
                                <img
                                    src={testimonial.image}
                                    alt={testimonial.name}
                                    className="w-16 h-16 rounded-full mx-auto mb-4 object-cover"
                                />
                                <h3 className="text-lg font-semibold text-center">{testimonial.name}</h3>
                                <p className="text-gray-600 text-center mt-3">{testimonial.feedback}</p>
                            </div>
                        </div>
                    ))}
                </Slider>

            <div className="w-full mt-40">
                <div className="container mx-auto">
                    <h1 className="text-blue-900 font-bold text-5xl mb-7 px-4 md:px-0">
                        Tesisimiz
                    </h1>
                    <p className="font-normal text-xl mb-10 px-4 md:px-0">
                        Atiye Atlı Yaşam tesisimiz, modern ve doğayla iç içe bir yapıya sahiptir. Aşağıdaki haritada tesisimizin bölümlerini inceleyebilir, her bir alan hakkında detaylı bilgi alabilirsiniz.
                    </p>
                    <div className="facility-map-container relative">
                        <img src="/img/dji_0111.jpg" alt="Atiye Atlı Yaşam Tesisi" className="w-full rounded-lg shadow-xl" />
                        
                        {facilitySpots.map((spot) => (
                            <div
                                key={spot.id}
                                className={`map-spot ${activeSpot === spot.id ? 'active' : ''}`}
                                style={{ top: spot.top, left: spot.left }}
                                onMouseEnter={() => setActiveSpot(spot.id)}
                                onMouseLeave={() => setActiveSpot(null)}
                            >
                                <div className="spot-pulse"></div>
                                {activeSpot === spot.id && (
                                    <div className="spot-info">
                                        <h3>{spot.name}</h3>
                                        <p>{spot.description}</p>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs;